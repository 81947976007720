export default {
  blogerName: 'MARKOLA',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@omarkola',
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@omarkolaa',
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/1markola/',
    },
    {
      name: 'telegram',
      url: 'https://t.me/omarkolaaa',
    }
  ],
  projects: [
    {
      name: 'monro',
      url: 'https://monrocasino.life/c706ae7a8',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/cca9ba455',
      gameTitle: 'STARDA QUEEN',
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/c84f411a0',
      gameTitle: 'Alien Fruits',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/c45421147',
      gameTitle: 'LEGZO PUNK',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/ce1bb579c',
      gameTitle: 'Jet Air',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/cb43a54ab',
      gameTitle: 'Doors of Sol',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/cdc3a5ec4',
      gameTitle: 'Doors Of Fresh',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/cc278d08e',
      gameTitle: 'IZZI ART',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>MARKOLA</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'MARKOLA',
  casinoBonus1: '<strong>100% + 500FS</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>para registro con código promocional</div>',
  copyMessage: 'Código promocional copiado',
  theme: 'dark',
  attentionText: ''
}
